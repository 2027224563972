<template>
  <div
      class="top"
      :class="type && `top_${type}`"
  >
    <div class="wrapper-content">
      <div class="top__content">
        <h1 class="top__title" v-html="title"></h1>
          <p
              v-if="description"
              v-html="description"
              class="top__description"
          ></p>
        <div
            v-if="showCalc"
            class="top__calculator"
        >
          <Calculator
            v-model="calculator"
            class="top__calculator-component"
          />
          <router-link :to="{ name: 'ContactInfo' }" class="top__button">
            Подобрать займ
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.11959e-07 9L12.17 9L6.58 14.59L8 16L16 8L8 -6.99382e-07L6.59 1.41L12.17 7L7.86805e-07 7L6.11959e-07 9Z"
              />
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './top.scss';
import Calculator from '@index/components/common/calculator/Calculator';

export default {
  name: 'Top',
    props: {
      showCalc: {
          type: Boolean,
          default: true
      },
        title: {
          type: String,
            required: true
        },
        description: {
          type: String
        },
        type: {
          type: String
        }
    },
  data() {
    return {
      calculator: 10000
    }
  },
  components: {
    Calculator,
  },
};
</script>
