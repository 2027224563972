<template>
    <div class="final-page">
        <div class="final-page__top">
            <Header class="final-page__header">
            </Header>
            <Top
                :showCalc="false"
                :title="`
                    Лучшие предложения<br/>
                    для вас<span class='final-page__username'>, ${username}</span>
                `"
                :description="`
                    Для гарантированного получения займа подайте заявки<br/>
                    как минимум в 3 компании.
                `"
                type="final"
            />
            <div class="final-page__content">
                <div class="final-page__offers">
                    <div
                        class="final-page__offer offer"
                        v-for="(offer, index) in offers"
                        :key="index"
                    >
                        <div class="offer__content">
                            <p
                                v-if="offer.isFeatured"
                                class="offer__special"
                            >
                                <span>0%</span><br/>
                                ставка
                            </p>
                            <div class="offer__logo">
                                <img :src="offer.logo" :alt="offer.title"/>
                            </div>
                            <div class="offer__info">
                                <div>
                                    <p class="offer__subtitle">
                                        Максимальная сумма:
                                    </p>
                                    <p class="offer__title">
                                        {{ offer.header }} ₽
                                    </p>
                                </div>
                                <div>
                                    <p class="offer__subtitle">
                                        Вероятность получения:
                                    </p>
                                    <p class="offer__chance">
                                        {{ offer.chance }}%
                                    </p>
                                </div>
                            </div>
                            <Button
                                class="offer__action"
                                :withoutIcon="true"
                                @click.native="openOffer(offer.link)"
                            >
                                Получить
                            </Button>
                            <p class="offer__subtitle">
                                {{ offer.subHeader }}
                            </p>
                        </div>
                    </div>
                </div>
                <Button
                    v-if="this.response.targets && this.response.targets.other.length && !showMore"
                    class="final-page__action"
                    @click.native="showMore = true"
                >
                     Показать еще
                    <template v-slot:icon>
                        <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 7L12 7L12 13L17 13L17 7ZM17 0L12 -4.37114e-07L12 6L17 6L17 0ZM11 -5.24537e-07L6 -9.61651e-07L6 6L11 6L11 -5.24537e-07ZM5 -1.04907e-06L1.1365e-06 -1.48619e-06L6.11959e-07 6L5 6L5 -1.04907e-06ZM11 7L6 7L6 13L11 13L11 7ZM5 13L5 7L5.24537e-07 7L0 13L5 13Z" fill="black"/>
                        </svg>
                    </template>
                </Button>
            </div>
            <Footer/>
        </div>
    </div>
</template>

<script>
import './final-page.scss'

import Store from '@index/store'
import Header from '@index/components/common/header/Header'
import Top from '@index/components/common/top/Top'
import final from '@index/api/final'
import Button from '@index/components/gui/button/Button'
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
    name: 'Final',
    data() {
        return {
            showMore: false,
            response: {}
        }
    },
    created() {
        this.getOffers();
    },
    methods: {
        async getOffers() {
            const response = await final();
            // @TODO предусмотреть ошибку? тест
            this.response = response;
        },
        openOffer(link) {
            window.location.href = link
        }
    },
    computed: {
        ...mapGetters({
            user: 'application/user',
        }),
        offers() {
            if (!this.showMore)
                return this.response.targets?.default

            return [
                ...this.response.targets?.default,
                ...this.response.targets?.other,
            ]
        },
        username() {
            return this.response.username || this.user.contactData?.firstname || 'гость'
        }
    },
    beforeRouteEnter(to, from, next) {
        if (!Cookies.get('sbg-cpa')) {
            next('/')
            Store.commit('application/load', false)
            return;
        }

        const redirect = Store.state.dictionary.dictionary.partnerRedirect;
        const { contactData: { phone } } = Store.getters['application/user']
        const isRefusenik = Store.getters['application/isRefusenik']

        if (phone) {
            next()
            Store.commit('application/load', false)
        } else if (isRefusenik && from.name !== null) {
            if (redirect) {
                window.location.href = redirect
            } else {
                next()
                Store.commit('application/load', false)
            }
        } else {
            next('/')
            Store.commit('application/load', false)
        }
    },
    components: {
        Header,
        Top,
        Button,
        Footer: () => import('@index/components/common/footer/Footer'),
    }
}
</script>